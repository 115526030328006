<script setup>
import { ref, computed } from 'vue'

const props = defineProps({
  src: {
    type: String,
    required: true,
  },
  poster: {
    type: String,
    required: true,
  },
})

const iframe = ref(null)
const active = ref(false)

const autoPlaySrc = computed(() => {
  const url = new URL(props.src)
  const params = new URLSearchParams(url.search)
  params.set('autoplay', '1')
  url.search = params.toString()
  return url.toString()
})

const play = () => {
  iframe.value.setAttribute('src', autoPlaySrc.value)
  active.value = true
}
</script>

<template>
  <div :class="['player', {'player--active': active}]" @click="play">
    <svg class="player__icon" xmlns="http://www.w3.org/2000/svg" width="115" height="115" viewBox="0 0 115 115" fill="none">
      <circle cx="57.5009" cy="57.4999" r="56.6767" fill="#D9D9D9" fill-opacity="0.3"/>
      <path d="M40.9707 88.9874V30.7363L91.35 52.7773L40.9707 88.9874Z" fill="white"/>
    </svg>

    <div class="player__poster">
      <img :src="poster" alt="Seed to Sale">
    </div>

    <div class="player__video">
      <iframe loading="lazy" ref="iframe" width="560" height="315" :src="src" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
    </div>
  </div>
</template>

<style scoped lang="scss">
.player {
  position: relative;

  .player__icon {
    cursor: pointer;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 45px;
    height: 45px;

    @media screen and (min-width: 768px) {
      width: 115px;
      height: 115px;
    }
  }

  .player__poster img {
    cursor: pointer;
    display: block;
    width: 100%;
    border-radius: 9px;

    @media screen and (min-width: 768px) {
      border-radius: 37px;
    }
  }

  .player__video {
    display: none;
    position: relative;
    padding-bottom: 56.25%;
    /* 16:9 */
    padding-top: 25px;
    height: 0;

    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
}
</style>
